import {VueWizard} from "@/vue-wizard";
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
    name: 'agent-list-element',
})
export default class AgentListElement extends VueWizard{
    @Prop() readonly user!: any;

    public showDetails : boolean = false;

    get initials(): string {
        const firstName =
            this.user && this.user.name && this.user.name.firstName
                ? this.user.name.firstName.substr(0, 1).toUpperCase()
                : "";
        const lastName =
            this.user && this.user.name && this.user.name.lastName
                ? this.user.name.lastName.substr(0, 1).toUpperCase()
                : "";
        return `${firstName}${lastName}`;
    }

    get FullName(): string {
        const firstName =
            this.user && this.user.name && this.user.name.firstName
                ? this.user.name.firstName
                : "";
        const lastName =
            this.user && this.user.name && this.user.name.lastName
                ? this.user.name.lastName
                : "";
        return `${firstName} ${lastName}`;
    }

    toggleDetails(){
        this.showDetails = !this.showDetails;
    }
} 