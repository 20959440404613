/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Axios from "axios";
import { VueWizard } from "@/vue-wizard";
import Component from 'vue-class-component';
import { store } from "@/app/store";
import { BASE_URL_MANAGER } from "@/config";
import { Prop, Watch } from 'vue-property-decorator';

import $ from 'jquery';
import { IUser } from "@/app/store/modules/user/user.models";

@Component({
    name: 'agent-create-form'
})
export default class AgentCreateForm extends VueWizard {
    @Prop() readonly elementId!: string;
    @Prop({ default: true }) readonly autoDisable!: boolean;
    @Prop() readonly parentElementId!: string | undefined;
    @Prop() readonly role!: string;
    @Prop() EventBus !: any;
    @Prop() company  !: any;
    public userLoged: IUser = store.state.user!.user;
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    public errorMessage: string = '';
    public validForm: boolean = true;
    public validUserName: boolean = true;
    public validPassword: boolean = true;
    public validUserNameFormat: boolean = true;
    public validSelect: boolean = true;
    public projects: any = [];
    public usernames: any = [];
    public formValues: any = {
        name: {
            firstName: '',
            lastName: ''
        },
        username: '',
        password: '',
        role: this.role,
        company_id: this.company
    };
    public project_id = '';

    created() {
        this.EventBus.$on('reload-modal', () => {
            this.cleanComponent();
            this.getCurrentProjects();
            this.getAllUsers();
        });
    }

    mounted() {

        this.userLoged = store.state.user!.user;
        this.getCurrentProjects();
        this.getAllUsers();
    }

    disable() {

        if (this.autoDisable) {
            $('#' + this.elementId).modal('hide');
        } else {
            this.elementDisableEnable('#' + this.elementId, '#' + this.parentElementId)
        }
    }

    elementDisableEnable(elementId: string, enableId: any): void {
        let modal: any = $(elementId);
        modal.modal('hide');

        modal = $(enableId);
        modal.modal('show');
    }

    async getCurrentProjects() {
        let url = `${BASE_URL_MANAGER}/project/company/${this.userLoged.company._id}`;
        await Axios(url).then(res => {
            this.projects = res.data.projects;
            this.project_id = '0';
        })
    }

    async getAllUsers() {
        let url = `${BASE_URL_MANAGER}/user/all/${store.state.user ? store.state.user.user.company._id : ''}`;
        await Axios.get(url).then(res => {
            res.data.users.forEach((user: { username: any; }) => {
                this.usernames.push(user.username)
            });
        })
    }

    validatePassword() {
        if (this.formValues.password.length < 8) {
            this.validUserName = false;
            this.errorMessage = 'La contraseña tiene que ser mínimo de 8 caracteres';
        } else {
            this.validUserName = true;
        }
    }

    validateUsername() {
        if (this.usernames.includes(this.formValues.username)) {
            this.validPassword = false;
            this.errorMessage = 'El nombre de usuario escogido ya existe';
        } else {
            this.validPassword = true;
        }
    }

    isEmail(): boolean {
        let serchfind: boolean;

        let regexp = new RegExp('/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/');

        serchfind = regexp.test(this.formValues.username);

        return serchfind
    }



    validateUserNameFormat() {
        let usernameSplitted = this.formValues.username.split(' ');
        if (usernameSplitted.length > 1) {
            this.validUserNameFormat = false;
            this.errorMessage = 'El nombre de usuario no puede contener espacios';
        } else {
            this.validUserNameFormat = true;
        }
    }

    validateSelect() {

        if (this.role === 'AGENT') {
            if (this.project_id != '0') {
                this.validSelect = true;
            } else {
                this.validSelect = false;
                this.errorMessage = 'Necesita escoger un proyecto válido';
            }
        }
    }

    validateForm() {

        let agent_create_url = '';
        let user_add_url = `${BASE_URL_MANAGER}/user/${this.company._id}`;
        let user_id = '';

        this.usernames = [];
        this.getAllUsers().then(() => {

            this.validatePassword();
            this.validateUsername();
            this.validateUserNameFormat();
            this.validateSelect();
            this.isEmail();


            if (this.role === 'AGENT') {
                this.validForm = this.validPassword && this.validUserName && this.validUserNameFormat && this.validSelect;
            } else if (this.role === 'ADMIN') {
                this.validForm = this.validPassword && this.validUserName && this.validUserNameFormat
            }


            if (this.validForm && this.role === 'AGENT') {
                Axios.post(user_add_url, this.formValues)
                    .then(async res => {
                        console.log(res);

                        user_id = res.data.user._id;
                        agent_create_url = `${BASE_URL_MANAGER}/agent/${user_id}`;
                        Axios.post(agent_create_url, {
                            projects: [this.project_id],
                           
                        }).then(() => {
                            this.$emit('new-agent');

                            this.$notification.success({
                                placement: "bottomRight",
                                message: "Usuario guardado exitosamente!",
                                description: "Los datos del usuario han sido guardados"
                            });
                            this.cleanComponent()
                            this.disable();
                            this.getAllUsers();

                        })

                    })
                    .catch(err => {
                        console.log('err', err);
                        this.$notification.warning({
                            placement: "bottomRight",
                            message: "Error",
                            description: err!.message
                        });
                    })
            } else if (this.validForm && this.role === 'ADMIN') {
                try {
                    Axios.post(user_add_url, this.formValues).then(() => {
                        this.$emit('new-agent');
                        this.$notification.success({
                            placement: "bottomRight",
                            message: "Usuario guardado exitosamente!",
                            description: "Los datos del usuario han sido guardados"
                        });
                        this.cleanComponent()
                        this.disable();
                    })
                } catch (err) {
                    throw err;
                }
            }

        });
    }

    close() {
        this.disable()
    }

    cleanComponent() {
        this.errorMessage = '';
        this.validForm = true;
        this.validUserName = true;
        this.validPassword = true;
        this.projects = [];
        this.usernames = [];
        this.formValues = {
            name: {
                firstName: '',
                lastName: ''
            },
            username: '',
            password: '',
            role: this.role
        };
        this.project_id = '0';

    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    @Watch('company')
    onChildChanged(val: any, oldVal: any) {


        this.company = val;
        this.formValues.company_id = val;
    }

}