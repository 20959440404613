import {VueWizard} from "@/vue-wizard";
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as bootstrap from "bootstrap"
import $ from 'jquery';

@Component({name:'user-name-modal'})
export default class UserNameModal extends VueWizard{
    @Prop() readonly elementId!: string;
    @Prop() agentNewName!: string;
    @Prop() agentNewLastName!: string;
    @Prop() scopedSlots!: string;
    @Prop() userId!: string;
    @Prop({default: true}) readonly autoDisable!: boolean;
    @Prop() readonly parentElementId!: string;

    public localName = '';
    public localLastName = '';
    public localPassword = '';

    disable(){
        if(this.autoDisable){
            $('#' + this.elementId).modal('hide');
        }else{
            this.elementDisableEnable('#' + this.elementId, '#' + this.parentElementId)
        }
    }

    elementDisableEnable(elementId: string, enableId: any): void {
        let modal: any = $(elementId);
        modal.modal('hide');
        modal = $(enableId);
        modal.modal('show');
    }

    get name(){
        this.localName = this.agentNewName;
        return this.agentNewName;
    }

    get lastName(){
        this.localLastName = this.agentNewLastName;
        return this.agentNewLastName;
    }

    changeName(e: any){
        this.localName = e;
    }

    changeLastName(e : any){
        this.localLastName = e;
    }

    submit(){
        console.log(this.localPassword);
        
        this.disable();

        this.$emit('ok', 
        {
            agentNewName: this.localName,
            agentNewLastName: this.localLastName,
            agentNewPassword: this.localPassword,
            userId!: this.userId
        });
    }
}