/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VueWizard } from "@/vue-wizard";
import Component from 'vue-class-component';
import { BASE_URL_MANAGER } from "@/config";
import { Prop } from 'vue-property-decorator';
import Axios from "axios";
import UserNameModal from '@/app/components/agents-management/dedicated-components/user-name-modal/UserNameModal.vue';
import AdminsList from '@/app/components/agents-management/dedicated-components/admins-list/AdminsList.vue';
import AgentCreateForm from '@/app/components/agents-management/dedicated-components/agent-create-form/AgentCreateForm.vue'
import { store } from '@/app/store';
import $ from 'jquery';
import Vue from 'vue';

@Component({
    name: 'admin-details',
    components: { UserNameModal, AdminsList, AgentCreateForm }
})
export default class AdminDetails extends VueWizard {
    @Prop() readonly elementId!: string;
    @Prop({ default: true }) readonly showActiveAdmin!: boolean;

    public user: any = null;
    public admins: Array<any> = [];
    public userId = '';
    public EventBus: Vue = new Vue();

    mounted(): void {
        this.getCurrentUser();
    }
    created(): void {
        this.userId = store.state.user!.user._id;

        this.retrieveAdmins();
    }

    openAdminCreateModal(actualId: string, newId: string): void {
        this.EventBus.$emit('reload-modal');
        this.elementDisableEnable(actualId, newId);
    }

    isEmpty(obj: any): boolean {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }

    getCurrentUser() {
        let url = `${BASE_URL_MANAGER}/user/${this.userId}`;
        try {
            Axios.get(url).then(res => {
                this.user = res.data.user;
            })
        } catch (err) {
            throw err;
        }
    }

    disable() {
        $(`#${this.elementId}`).modal('hide');
    }

    elementDisableEnable(elementId: string, enableId: any): void {
        let modal: any = $(elementId);
        modal.modal('hide');
        modal = $(enableId);
        modal.modal('show');
    }

    changeUserInfo(values: any): void {

        let agentNewName = values.agentNewName
        let agentNewLastName = values.agentNewLastName
        let userId = values.userId

        let user = this.user;

        if (user && user.name) {
            user.name.firstName = agentNewName;
            user.name.lastName = agentNewLastName;
            store.state.user!.user.name!.firstName = agentNewName;
            store.state.user!.user.name!.lastName = agentNewLastName;
        }

        if (user && user.name) {
            Axios.put(`${BASE_URL_MANAGER}/user/${userId}`, {
                name: { firstName: user.name.firstName, lastName: user.name.lastName }
            })
        }
    }

    retrieveAdmins() {
        let url = `${BASE_URL_MANAGER}/user/all/${store.state.user ? store.state.user.user.company._id : ''}`;
        this.admins = [];
        try {
            Axios.get(url).then(res => {
                res.data.users.forEach((user: { _id: string, role: string; }) => {

                    if (user.role === 'ADMIN' && user._id != this.userId) {
                        this.admins.push(user);
                    }
                    if (user._id == this.userId) {
                        this.user = user;
                    }
                });
            });
        } catch (err) {
            throw err;
        }

    }
}